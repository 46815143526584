import styles from './Button.module.scss'
import cn from 'classnames'
import Link from 'next/link'

function Button({ color = null, outline = false, loading = false, children, className, ...props }) {
  return (
    <WrapperComponent
      className={cn(styles['root'], {
        [styles['root--outline']]: outline,
        [styles[`root--${color}`]]: color,
        [styles['root--loading']]: loading,
        [className]: className,
      })}
      {...props}
    >
      <span>{children}</span>

      {loading && (
        <div className={styles['loader']}>
          {new Array(3).fill(null).map((_, index) => (
            <span key={index} />
          ))}
        </div>
      )}
    </WrapperComponent>
  )
}

function WrapperComponent({ children, ...props }) {
  return props.href ? (
    <Link {...props}>{children}</Link>
  ) : (
    <button
      type="button"
      {...props}
    >
      {children}
    </button>
  )
}

export default Button
