import AppFooter from '@/components/AppFooter'
import AppNavigation from '@/components/AppNavigation'
import AppSeo from '@/components/AppSeo'

function AppLayout({ page, data, children }) {
  return (
    <>
      <AppSeo page={page} />

      <AppNavigation menuItems={data.appMenu.menuItems.nodes} />

      {children}

      <AppFooter
        menuItems={data.footerMenu.menuItems.nodes}
        socials={data.theme.themeSettingsAcf.socials}
        contactInfo={data.theme.themeSettingsAcf.contactInfo}
        legalPages={data.theme.themeSettingsAcf.legalPages}
      />
    </>
  )
}

export default AppLayout
