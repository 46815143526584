import styles from './AppFooter.module.scss'
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline'
import cn from 'classnames'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import Logo from '@/assets/svg/logo--large.svg'
import HTML from '@/components/HTML'
import renderCustomIcon from '@/utils/renderCustomIcon'
import slugify from '@/utils/slugify'

function AppFooter({ menuItems, contactInfo, legalPages, socials, className, ...props }) {
  const menus = menuItems.filter((item) => item.childItems.nodes.length > 0)
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    function toggleIsExpanded() {
      const mql = window.matchMedia('(max-width: 1023px)')
      setIsExpanded(!mql.matches)
    }

    toggleIsExpanded()

    window.addEventListener('resize', toggleIsExpanded)
    return () => window.removeEventListener('resize', toggleIsExpanded)
  }, [])

  return (
    <footer
      className={cn(styles['root'], { [className]: className })}
      {...props}
    >
      <section className={cn('sm:container', styles['links'])}>
        <Link
          title="Brancheorganisatie Kinderopvang"
          aria-label="Brancheorganisatie Kinderopvang"
          href="/"
          className={cn(styles['logo'])}
        >
          <Logo />
        </Link>

        <div className={cn(styles['link-wrapper'])}>
          <ul className={cn(styles['app-links'])}>
            {menus.map((menu) => {
              return (
                <li key={menu.label}>
                  <button
                    tabIndex={isExpanded ? -1 : 0}
                    aria-expanded={isExpanded}
                    aria-controls={'section-' + slugify(menu.label)}
                    id={'accordion-' + slugify(menu.label)}
                    type="button"
                    title="Social media link"
                    onClick={(event) => {
                      if (isExpanded) return
                      const expanded = event.currentTarget.getAttribute('aria-expanded') == 'true'
                      event.currentTarget.setAttribute('aria-expanded', !expanded)
                    }}
                  >
                    <span>{menu.label}</span>

                    <div>
                      <PlusIcon />
                      <MinusIcon />
                    </div>
                  </button>

                  <ul
                    role="region"
                    id={'section-' + slugify(menu.label)}
                    aria-labelledby={'accordion-' + slugify(menu.label)}
                  >
                    {menu.childItems.nodes.map((item) => {
                      return (
                        <li key={item.label + item.uri}>
                          <Link
                            target={item.target}
                            aria-label={item.label}
                            href={item.uri}
                          >
                            {item.label}
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </li>
              )
            })}

            <li>
              <button
                tabIndex={isExpanded ? -1 : 0}
                aria-expanded={isExpanded}
                aria-controls="section-contact"
                id="accordion-contact"
                type="button"
                onClick={(event) => {
                  if (isExpanded) return
                  const expanded = event.currentTarget.getAttribute('aria-expanded') == 'true'
                  event.currentTarget.setAttribute('aria-expanded', !expanded)
                }}
              >
                <span>Contact</span>

                <div>
                  <PlusIcon />
                  <MinusIcon />
                </div>
              </button>

              <ul
                role="region"
                id="section-contact"
                aria-labelledby="accordion-contact"
                className={cn(styles['contact-links'])}
              >
                <li>
                  <HTML>{contactInfo.mailboxAddress}</HTML>
                </li>

                <li>
                  <Link href={'tel:' + contactInfo.telephoneNumber}>{contactInfo.telephoneNumber}</Link>
                </li>

                <li>
                  <Link
                    href={'mailto:' + contactInfo.emailAddress}
                    target="_blank"
                  >
                    {contactInfo.emailAddress}
                  </Link>
                </li>
              </ul>
            </li>
          </ul>

          <div className={cn(styles['social-links'])}>
            <ul className={cn(styles['social-links__main'])}>
              {Object.entries(socials).map(([key, value]) => {
                const Icon = renderCustomIcon(key)

                return (
                  <li key={key}>
                    <Link
                      title={key}
                      aria-label={key}
                      target="_blank"
                      href={value}
                    >
                      <Icon />
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </section>

      <section className={cn(styles['terms-bar'])}>
        <div className={cn(styles['terms-bar__main'])}>
          <p className={cn(styles['text'])}>© {new Date().getFullYear()} Brancheorganisatie Kinderopvang</p>

          {(legalPages?.disclaimer || legalPages?.privacyPolicy) && (
            <ul className={cn(styles['terms'])}>
              {legalPages.disclaimer && (
                <li>
                  <Link href={legalPages.disclaimer.uri}>{legalPages.disclaimer.title}</Link>
                </li>
              )}

              {legalPages.privacyPolicy && (
                <li>
                  <Link href={legalPages.privacyPolicy.uri}>{legalPages.privacyPolicy.title}</Link>
                </li>
              )}
            </ul>
          )}
        </div>
      </section>
    </footer>
  )
}

export default AppFooter
