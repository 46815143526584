import Head from 'next/head'
import { useRouter } from 'next/router'

function AppSeo({ page }) {
  const seo = getSeoProperty(page)
  const { asPath } = useRouter()

  const seoValues = {
    title: seo ? seo.title : 'Brancheorganisatie Kinderopvang',
    description: seo ? seo.metaDesc : page?.homeAfc?.introHeader?.title ? page?.homeAfc?.introHeader?.title : '',
    image: seo && seo.opengraphImage ? seo.opengraphImage.sourceUrl : null,
    url: process.env.NEXT_PUBLIC_VERCEL_URL + asPath,
    follow: seo ? seo.metaRobotsNofollow : 'follow',
    index: seo ? seo.metaRobotsNoindex : 'index',
  }

  return (
    <Head>
      <meta
        httpEquiv="X-UA-Compatible"
        content="IE=Edge"
      />
      <title>{seoValues.title}</title>

      <link
        rel="icon"
        href="https://kinderopvang.nl/favicon.ico"
        type="image/x-icon"
      />

      <meta
        property="og:locale"
        content="en_US"
      />

      <meta
        name="robots"
        content={`max-snippet:-1, max-image-preview:large, max-video-preview:-1, ${seoValues.follow}, ${seoValues.index}`}
      />

      <meta
        name="viewport"
        content="initial-scale=1.0, width=device-width"
      />

      <meta
        property="description"
        content={seoValues.description}
      />

      <meta
        property="og:site_name"
        content={seoValues.opengraphSiteName}
      />

      <meta
        property="og:title"
        content={seoValues.title}
      />

      <meta
        property="twitter:title"
        content={seoValues.title}
      />

      {seoValues?.description && (
        <>
          <meta
            property="description"
            content={seoValues.description}
          />

          <meta
            name="og:description"
            content={seoValues.description}
          />

          <meta
            property="twitter:description"
            content={seoValues.description}
          />
        </>
      )}

      <meta
        property="og:site_name"
        content={seoValues.url}
      />

      <meta
        property="og:url"
        content={seoValues.url}
      />

      {seoValues.image && (
        <>
          <meta
            property="og:image"
            content={seoValues.image}
          />
          <meta
            property="twitter:image"
            content={seoValues.image}
          />
        </>
      )}

      <meta
        property="og:type"
        content="website"
      />

      <meta
        property="twitter:card"
        content="summary_large_image"
      />
    </Head>
  )
}

export default AppSeo

function getSeoProperty(object) {
  if (!object) return null
  if (object.hasOwnProperty('seo')) return object.seo

  for (var i = 0; i < Object.keys(object).length; i++) {
    if (typeof object[Object.keys(object)[i]] == 'object') {
      var o = getSeoProperty(object[Object.keys(object)[i]])
      if (o != null) return o['seo']
    }
  }

  return null
}
