import apiClient from '@/lib/api-client'

function UserService() {
  async function csrf() {
    return await apiClient('/sanctum/csrf-cookie')
  }

  async function show({ Cookie }) {
    await csrf()

    return await apiClient('/user', { headers: { Cookie } })
  }

  async function changePassword(data) {
    await csrf()

    return await apiClient('/user/change-password', {
      method: 'PUT',
      body: data,
    })
  }

  async function changeEmail(data) {
    await csrf()

    return await apiClient('/user/change-email', {
      method: 'POST',
      body: data,
    })
  }

  async function verifyNewEmail({ key }) {
    await csrf()

    return await apiClient('/verify-new-email/' + key)
  }

  async function unsubscribeFromNewsletter(token) {
    await csrf()

    return await apiClient('/newsletter/unsubscribe/' + token)
  }

  async function updateNewsletterPreferences(data) {
    await csrf()

    return await apiClient('/user/newsletter-preferences', {
      method: 'PUT',
      body: data,
    })
  }

  async function update(data) {
    await csrf()

    return await apiClient('/user', {
      method: 'PUT',
      body: data,
    })
  }

  async function tribe({ Cookie }) {
    await csrf()

    return await apiClient('/user/tribe-account', { headers: { Cookie } })
  }

  async function organization({ Cookie }) {
    await csrf()

    return await apiClient('/user/tribe-organization', { headers: { Cookie } })
  }

  function contribution() {
    async function show({ Cookie }) {
      await csrf()

      return await apiClient('/contribution-calculation', { headers: { Cookie } })
    }

    async function store(data) {
      await csrf()

      return await apiClient('/contribution-calculation', {
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    }

    return {
      show,
      store,
    }
  }

  return {
    show,
    changePassword,
    changeEmail,
    verifyNewEmail,
    unsubscribeFromNewsletter,
    updateNewsletterPreferences,
    update,
    tribe,
    organization,
    contribution,
  }
}

export default UserService
