import cn from 'classnames'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

function HTML({ children, className, ...props }) {
  return (
    <ReactMarkdown
      rehypePlugins={[rehypeRaw]}
      className={cn({ [className]: className })}
      {...props}
    >
      {children}
    </ReactMarkdown>
  )
}

export default HTML
