import { getCookie } from 'cookies-next'

async function apiClient(url, props) {
  const isMultipartFormData = props?.headers?.['Content-Type'] === 'multipart/form-data'
  const hasCookie = !!props?.headers?.Cookie
  const XSRF_TOKEN = getCookie('XSRF-TOKEN')

  return await fetch(process.env.NEXT_PUBLIC_API_ENDPOINT + url, {
    ...props,
    headers: {
      Accept: 'application/json',
      ...(!isMultipartFormData && { 'Content-Type': 'application/json' }),
      ...(XSRF_TOKEN && { 'X-XSRF-TOKEN': XSRF_TOKEN }),
      ...(hasCookie && { Cookie: props?.headers?.Cookie }),
    },
    credentials: 'include',
  })
}

export default apiClient
