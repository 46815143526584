import icons from '@/assets/svg'

function renderCustomIcon(icon) {
  if (!icon) return null
  const selectedIcon = icons['icon--' + icon]

  return selectedIcon || icons['icon--chevron-right']
}

export default renderCustomIcon
